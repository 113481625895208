<template>
  <div id="notes-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead">
      {{ activeRoute.description }}
    </p>

    <router-link to="/notes/add" class="btn btn-primary mb-5">Neue Notiz erstellen</router-link>

    <IntroBanner v-if="!(notes && notes.length)" link="/notes/add"></IntroBanner>

    <div v-if="filter !== ''" class="mb-2">
      <button @click="filter = ''" class="btn btn-sm btn-dark">Filter entfernen</button>
    </div>
    <div v-for="(note, index) in notes"
         class="card p-3 mb-4"
         :style="'background:' + note.color"
         :key="index"
    >
      <div class="edit-button">
        <router-link :to="'/notes/edit/' + note.id"><i class="fad fa-pencil"></i></router-link>
      </div>
      <div class="remove-button" @click="remove(note.id)">
        <i class="fas fa-times"></i>
      </div>
      <div v-html="note.text"></div>
      <hr class="mb-1">
      <div class="row text-muted">
        <div class="col-3">
          <p class="small mb-0 mt-1">{{ note.createdAt }}</p>
        </div>
        <div class="col-9">
          <div class="text-right">
            <span v-for="(tag, index) in note.tags" @click="filter = tag" class="badge badge-dark" :key="index">
              {{ tag }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import IntroBanner from "@/components/IntroBanner";
export default {
  name: 'Notes',
  components: {
    IntroBanner
  },
  data() {
    return {
      filter: ""
    }
  },
  computed: {
    notes() {
      return this.project.notes
          .map(note => {
            note.createdAt = this.timestampToDate(note.createdAt);
            return note;
          })
          .filter(note => !this.filter || note.tags.find(t => t === this.filter));
    }
  },
  methods: {
    remove(id) {
      let index = this.project.notes.findIndex(n => n.id === id);
      this.project.notes.splice(index, 1);
      this.$store.dispatch('project/updateProjectByProp', {prop: 'notes', data: this.project.notes})
          .then(res => {
            console.debug(res);
          });
    }
  }
}
</script>
